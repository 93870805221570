<template>
  <div class="introduce">
    <div class="topImg">
      <img src="./img/pointRule.png" alt="" />
    </div>
    <div class="body">
      <div class="body-center">
        <div class="body-c">
          <div class="item">
            <div class="item-t">1. 用户注册：</div>
            <div class="item-b">获得50积分，完成注册即可。</div>
          </div>
          <div class="item">
            <div class="item-t">2. 用户完善资料：</div>
            <div class="item-b">
              必须填写完整房号，并审核通过才能获得，且只能获得一次。
            </div>
          </div>
          <div class="item">
            <div class="item-t">3. 每日签到：</div>
            <div class="item-b">
              首日签到获取1积分值；<br />
              连续签到两日，第二日获取1积分值；<br />
              连续签到三日，第三日获取2积分值；<br />
              连续签到四日，第四日获取1积分值；<br />
              连续签到五日，第五日获取4积分值；<br />
              连续签到六日，第六日获取1积分值；<br />
              连续签到七日，第七日获取7积分值。<br />
              断签或者连续签到满7日后重新计算。
            </div>
          </div>
          <div class="item">
            <div class="item-t">4. 参加活动（含热门活动、幸福学堂）：</div>
            <div class="item-b">
              用户报名参与活动后可以获得5积分值（扫描活动签到二维码获得）。
            </div>
          </div>
          <div class="item">
            <div class="item-t">5. 运动打卡：</div>
            <div class="item-b">
              用户提交心愿信息经运营官审核后，可获取2积分值（每人每日仅第一次发布可以获得积分哦）。
            </div>
          </div>
          <div class="item">
            <div class="item-t">6. 申请社区达人：</div>
            <div class="item-b">
              提交社区达人申请，运营官审核通过后可获取30积分值。
            </div>
          </div>
          <div class="item">
            <div class="item-t">7. 社区达人举办活动：</div>
            <div class="item-b">
              社区达人提交举办活动申请，运营官审核通过并成功组建活动后（中途取消将无法发放奖励），可获取100积分值。
            </div>
          </div>
          <!-- <div class="item">
            <div class="item-t">8. 运动打卡：</div>
            <div class="item-b">
              用户上传运动软件打卡照片经明珠审核后，可获取2积分，每日仅可上传一次，审核失败允许重新提交，审核通过后，当天无法再发起提交。<br />
              另外运动步数达到5000步数可领取1积分，达到10000步数可领取2积分，最高可领取2积分。
            </div>
          </div>
          <div class="item">
            <div class="item-t">9. 申请达人：</div>
            <div class="item-b">
              提交达人申请，运营官明珠审核通过后可获取30积分。
            </div>
          </div>
          <div class="item">
            <div class="item-t">10. 达人举办活动：</div>
            <div class="item-b">
              达人提交举办活动申请，运营官明珠审核通过并成功组建活动后，可获取10-50积分。
            </div>
          </div>
          <div class="item">
            <div class="item-t">11. 明珠超话区：</div>
            <div class="item-b">
              明珠每个超话结束之前，评论区点赞前十名用户可获取2积分。
            </div>
          </div>
          <div class="item">
            <div class="item-t">12. 添加运营官小和微信：</div>
            <div class="item-b">
              用户添加运营官微信，上传与运营官明珠聊天截图可获取2积分。
            </div>
          </div> -->
        </div>
        <!-- <div class="body-b">
          <div class="title"><span>注意事项</span></div>
          <p>积分礼品数量有限，先领取先得哦~</p>
          <p><b>领取方式：</b>凭积分至运营官明珠处核销领取</p>
          <p><b>领取地点：</b>明珠居委会</p>
          <p><b>领取时间：</b>周一至周五9:00-18:00，周末及法定节假日除外</p>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "introduce",
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.introduce {
  min-height: 100%;
  padding: 0 0 68px;
  // background: #126cfe; // #3288FF
  background: linear-gradient(90deg, #126cfe 0%, #3288ff 100%);
  .topImg {
    // height: 600px;
    height: 336px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .body {
    padding: 0 32px;
    margin-top: -18px;
    z-index: 99;
    position: relative;
    .body-center {
      background: #ffffff;
      border-radius: 16px;
      padding: 32px 32px 0px;
      .body-c {
        padding-bottom: 40px;
      }
      .item {
        margin-bottom: 48px;
        font-size: 32px;
        // font-weight: 400;
        // color: #646668;
        line-height: 44px;
        .item-t {
          font-size: 32px;
          font-weight: bold;
          color: #323334;
          line-height: 44px;
          margin-bottom: 8px;
        }
        .item-b {
          color: #9d9d9d;
          font-size: 28px;
        }
      }
      .title {
        font-size: 32px;
        text-align: center;
        font-weight: bold;
        color: #2992fd;
        margin-bottom: 22px;
        line-height: 44px;
        span {
          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 150px;
            height: 4px;
            background: linear-gradient(
              270deg,
              #2992fd 0%,
              rgba(41, 146, 253, 0) 100%
            );
            border-radius: 2px;
            left: -170px;
            top: 0;
            bottom: 0;
            margin: auto;
          }
          &::after {
            content: "";
            position: absolute;
            width: 150px;
            height: 4px;
            background: linear-gradient(
              270deg,
              #2992fd 0%,
              rgba(41, 146, 253, 0) 100%
            );
            border-radius: 2px;
            right: -170px;
            top: 0;
            bottom: 0;
            margin: auto;
            transform: rotate(180deg);
          }
        }
      }
      .body-b {
        padding-bottom: 66px;
        p {
          font-size: 28px;
          font-weight: 400;
          color: #646668;
          line-height: 56px;
          b {
            font-weight: 700;
            color: #000;
          }
        }
      }
    }
  }
}
</style>
